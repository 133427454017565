import { PartnerType } from '../../interfaces/Partner';
import { RegionType } from '../../helpers/RegionType';
import { Theme } from './Theme';
import { defaultThemes } from './defaultThemes';
import { ProgramType } from '../../interfaces/ProgramType';

type Themes = Record<PartnerType, Record<ProgramType, Partial<Record<RegionType, Theme>>>>;

export function getTheme(partner: PartnerType, program: ProgramType, region: RegionType): Theme {
  const themes: Themes = {
    'uber-pro': {
      fuel: {
        //
      },
      pulse: {
        //
      },
    },
    'amazon-prime': {
      fuel: {
        //
      },
      pulse: {
        //
      },
    },
  };

  if (!themes[partner] || !themes[partner][program] || !themes[partner][program][region]) {
    return defaultThemes[program];
  }
  return themes[partner][program][region] || defaultThemes[program];
}

export function generateStyleFromTheme(theme: Theme): string {
  return `
      :root {
        --color-primary-base: ${theme.colors.primaryBase};
        --color-primary-dark: ${theme.colors.primaryDark};
        --color-primary-light: ${theme.colors.primaryLight};
        --color-secondary-base: ${theme.colors.secondaryBase};
        --color-secondary-dark: ${theme.colors.secondaryDark};
        --color-secondary-light: ${theme.colors.secondaryLight};
        --color-tertiary-base: ${theme.colors.tertiaryBase};
        --color-tertiary-dark: ${theme.colors.tertiaryDark};
        --color-tertiary-light: ${theme.colors.tertiaryLight}
      }
    `;
}
