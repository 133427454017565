import { ReactElement } from 'react';
import { useTheme } from '../../utils/theme/useTheme';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { getSupportedLanguages } from '../../helpers/PartnerRegionPropertySelector';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useAppState } from '../app/useAppState';
import history from '../../routing/BrowserHistory';
import { ReactComponent as BackArrow } from '../../assets/images/icons/tail-left-32.svg';

import { useAtomValue } from 'jotai';
import { programAtom } from '../../store';
import { useShowBackButton } from './useShowBackButton';

import styles from './Header.module.scss';

interface Props {
  backButton?: boolean;
  onBack?: () => void;
}
const Header = ({ backButton, onBack }: Props): ReactElement => {
  const program = useAtomValue(programAtom);
  const theme = useTheme();
  const showBackButton = useShowBackButton();
  const { currentPartner, currentPartnerRegion } = useApplicationContext();
  const languages = getSupportedLanguages(currentPartner, currentPartnerRegion);
  const { changeLanguage } = useAppState();

  function handleBack() {
    if (onBack) {
      onBack();
      return;
    }
    history.goBack();
  }

  if (!theme) {
    return <div data-testid="header" className={styles.header}></div>;
  }

  return (
    <div data-testid="header" className={styles.header}>
      <div className={styles.languageBack}>
        {languages.length > 1 && (
          <LanguageSelector
            className={styles.language}
            partner={currentPartner}
            partnerRegion={currentPartnerRegion}
            onLanguageChange={changeLanguage}
          />
        )}
        {(backButton || showBackButton) && (
          <button onClick={handleBack} data-testid="header__back-button" className={styles.back}>
            <BackArrow />
          </button>
        )}
      </div>
      {currentPartnerRegion === 'us' ? (
        <div className={styles.earnifyLogo}>
          <img alt="" src={`/images/${currentPartner}/${program}/earnify-us.svg`}></img>
        </div>
      ) : (
        <div></div>
      )}
      <div className={styles.logos}>
        <img
          data-testid="header-logo-primary"
          alt="Primary logo"
          src={`/images/${currentPartner}/${program}/bp-${currentPartnerRegion}.svg`}
        ></img>
        <span className={styles.separator}></span>
        <img
          className={styles.partnerLogo}
          data-testid="header-logo-secondary"
          alt="Secondary logo"
          src={`/images/${currentPartner}/${program}/partner-logo.svg`}
        ></img>
      </div>
    </div>
  );
};

export default Header;
