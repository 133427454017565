import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../../contexts/ApplicationContext';

export enum ActivityIndicatorType {
  FullPage,
  InlineSmall,
  InlineMedium,
}

type ActivityIndicatorPropsType = {
  type: ActivityIndicatorType;
};

type ActivityIndicatorStylingType = {
  container: string;
  spinner: string;
  title: string;
  text: string;
};

const ActivityIndicator = (props: ActivityIndicatorPropsType): ReactElement => {
  const { t } = useTranslation('default');
  const { currentPartnerRegion, currentPartner } = useApplicationContext();
  const regionCustomRedirectMessage: any = {
    'uber-pro': {
      au: 'redirectingUberProAu',
      us: 'redirectingUberProUs',
      nl: 'redirecting',
      mx: 'redirecting',
      uk: 'redirecting',
    },
    'amazon-prime': {
      au: 'redirecting',
      us: 'redirectingAmazonPrimeUs',
      nl: 'redirecting',
      mx: 'redirecting',
      uk: 'redirecting',
    },
  };

  /* istanbul ignore next: No need to codecov */
  const getStyling = (type: ActivityIndicatorType): ActivityIndicatorStylingType => {
    const fullPageTitle =
      currentPartner && currentPartnerRegion
        ? t(regionCustomRedirectMessage[currentPartner][currentPartnerRegion])
        : t('redirecting');

    switch (type) {
      case ActivityIndicatorType.FullPage:
        return {
          container: 'row h-100 loader__fullpage loader__justify justify-content-center align-items-center',
          spinner: 'loader loader__boxed',
          title: fullPageTitle,
          text: fullPageTitle + '...',
        };
      case ActivityIndicatorType.InlineSmall:
        return {
          container: 'row h-100 loader__justify justify-content-center align-items-center no-gutters',
          spinner: 'loader loader__sm',
          title: t('loading'),
          text: t('loading') + '...',
        };
      case ActivityIndicatorType.InlineMedium:
        return {
          container: 'row h-100 loader__justify justify-content-center align-items-center no-gutters',
          spinner: 'loader loader__md',
          title: t('loading'),
          text: t('loading') + '...',
        };
    }
  };

  const styling = getStyling(props.type);

  return (
    <div className="container" data-testid="activity_indicator">
      <div className={styling.container}>
        <div className={styling.spinner} title={styling.title}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {props.type === ActivityIndicatorType.FullPage ? <p className="loader__message">{styling.text}</p> : <></>}
      </div>
    </div>
  );
};

export default ActivityIndicator;
