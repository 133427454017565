export default {
  tier: {
    green: 'Green',
    blue: 'Blue',
    gold: 'Gold',
    diamond: 'Diamond',
    platinum: 'Platinum',
  },

  homePageUk: {
    welcome: 'Welcome to BPme Rewards!',
    description:
      'To earn and redeem your BPme Rewards points, scan your card with every purchase at bp service stations.',
    myCard: 'My card',
    howItWorks: 'How it works',

    addToIOSWallet: 'Add to ios wallet',
    addToAdroidWallet: 'Add to android wallet',
  },

  homePageAu: {
    welcome: 'Welcome to BP Rewards Unlocked!',
    description: 'To collect and redeem your points, scan your card at a Participating BP Site.',
    myCard: 'My card',
    howItWorks: 'How it works',

    addToIOSWallet: 'Add to ios wallet',
    addToAdroidWallet: 'Add to android wallet',
  },

  homePageUs: {
    welcome: 'Welcome to earnify',
    description: 'Drivers with Uber + earnify™ save on every gallon at bp and Amoco stations.',
    myCard: 'My Loyalty ID',
    howItWorks: 'How it works',

    addToIOSWallet: 'Add to ios wallet',
    addToAdroidWallet: 'Add to android wallet',
  },

  homePageBarcodeCard: {
    uberPro: 'uber pro',
    tier: 'tier',
    worth: 'worth £{{value}}',
    uberProt: 'uber pro {{tier}} {{icon}}',
  },

  homePageBarcodeCardUs: {
    myLoyaltyId: 'My Loyalty ID',
  },

  homePageMyCardAu: {
    title: 'Take advantage of our exclusive in-store offers with BP Rewards Unlocked:',

    blueUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    blueUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    blueUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    blueUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    greenUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    greenUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    greenUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    greenUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    goldUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    goldUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    goldUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    goldUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    platinumUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    platinumUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    platinumUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    platinumUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    diamondUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    diamondUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    diamondUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    diamondUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    howToFindVideo: 'Watch the ‘how to find a site’ video here',
    findAParticipatingSite: 'Find a participating site',

    didYouKnowTitle: 'Did you know?',

    blueDidYouKnow:
      'Top 10% of Uber Blue Tier drivers save <strong>$X per year</strong> on average. And top 10% of Uber Diamond Tier drivers save <strong>$X per year</strong> on average.*',
    greenDidYouKnow:
      'Top 10% of Uber Blue Tier drivers save <strong>$X per year</strong> on average. And top 10% of Uber Diamond Tier drivers save <strong>$X per year</strong> on average.*',
    goldDidYouKnow:
      'Top 10% of Uber Blue Tier drivers save <strong>$X per year</strong> on average. And top 10% of Uber Diamond Tier drivers save <strong>$X per year</strong> on average.*',
    platinumDidYouKnow:
      'Top 10% of Uber Blue Tier drivers save <strong>$X per year</strong> on average. And top 10% of Uber Diamond Tier drivers save <strong>$X per year</strong> on average.*',
    diamondDidYouKnow:
      'Top 10% of Uber Blue Tier drivers save <strong>$X per year</strong> on average. And top 10% of Uber Diamond Tier drivers save <strong>$X per year</strong> on average.*',
  },

  homePageMyCardUk: {
    didYouKnowTitle: 'Did you know?',

    blueDidYouKnow:
      'Active Blue tier Members on average can save <strong>up to £12 per year</strong> *. If you move up to Gold, you could save on average an <strong>extra £26 a year</strong>',
    greenDidYouKnow:
      'Active Green tier Members on average can save <strong>up to £12 per year</strong> *. If you move up to Gold, you could save on average an <strong>extra £26 a year</strong>',
    goldDidYouKnow:
      'Active Gold tier Members on average can save <strong>up to £38 per year</strong> *. If you move up to Platinum, you could save on average an <strong>extra £28 a year</strong>',
    platinumDidYouKnow:
      'Active Platinum tier Members on average can save <strong>up to £66 per year</strong> *. If you move up to Diamond, you could save on average an <strong>extra £56 a year</strong>',
    diamondDidYouKnow:
      'Active Diamond tier Members on average can save <strong>up to £10 per month</strong> or <strong>£122 Pounds per year</strong> *',
    didYouKnowExplanation: '* Based on data gathered during the availability of the program to {{tier}} tier Members',
  },

  homePageMyCardUs: {
    howToRedeem: 'How to redeem',
    howToRedeemStep1: 'If you’re paying at a pump, first press “Cancel”',
    howToRedeemStep2: 'Enter your phone number as your Loyalty ID',

    didYouKnowTitle: 'Did you know?',
    blueDidYouKnow: 'The average Blue Tier driver saves an extra $28 per year with earnify™ *',
    greenDidYouKnow: 'The average Blue Tier driver saves an extra $28 per year with earnify™ *',
    goldDidYouKnow: 'The average Blue Tier driver saves an extra $28 per year with earnify™ *',
    platinumDidYouKnow: 'The average Blue Tier driver saves an extra $28 per year with earnify™ *',
    diamondDidYouKnow: 'The average Blue Tier driver saves an extra $28 per year with earnify™ *',

    blueBasedOn: '* Based on Blue Drivers average 2021 - 2022 transaction data. Assumes 3.9 average trips per month.',
    greenBasedOn: '* Based on Blue Drivers average 2021 - 2022 transaction data. Assumes 3.9 average trips per month.',
    goldBasedOn: '* Based on Blue Drivers average 2021 - 2022 transaction data. Assumes 3.9 average trips per month.',
    platinumBasedOn:
      '* Based on Blue Drivers average 2021 - 2022 transaction data. Assumes 3.9 average trips per month.',
    diamondBasedOn:
      '* Based on Blue Drivers average 2021 - 2022 transaction data. Assumes 3.9 average trips per month.',
  },

  homePageHowItWorksAu: {
    title: 'Enjoy our exclusive offer for Uber driver-partner or delivery person:',
    learnMore: 'Learn more',

    earnCard: 'Save {{discount.fuel.save}}¢ on every litre of fuel with Uber Pro {{partnerLevel}} Tier',
    saveCard: 'Save even more after moving to a higher Uber tier',

    aboutTheOffer: 'About the offer',

    download: 'Download the full BPme app for additional benefits:',

    blueUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    blueUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    blueUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    blueUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    greenUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    greenUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    greenUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    greenUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    goldUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    goldUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    goldUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    goldUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    platinumUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    platinumUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    platinumUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    platinumUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',

    diamondUnlockedCoffee: 'Wild Bean Cafe:<br /> 50% off barista<br /> made coffee',
    diamondUnlockedGift1: 'Red Bull: $2 x<br /> 250ml varieties',
    diamondUnlockedGift2: 'Cool Ridge Water<br /> multipack: $1 x<br /> 600ml or $7 for<br /> 12x600ml ',
    diamondUnlockedGasPump: 'Miniwash Carwash:<br /> $5 with minimum<br /> 25L fuel purchase',
  },

  homePageHowItWorksUk: {
    title: 'Enjoy our exclusive offer for Uber drivers and couriers:',
    learnMore: 'Learn more',

    earnCard:
      'Earn {{discount.fuel.points}} points per litre of fuel or per £{{discount.inStore.value}} of in-store purchases',

    redeemCard: 'Redeem the points on fuel, in-store purchases or vouchers online',
    saveCard: 'Save even more after moving to a higher Uber tier',
    download: 'Download the full BPme app for additional benefits:',

    redeemOnVouchers: 'Redeem on vouchers',
    redeemOnVouchersDescription: 'You can redeem your points on digital vouchers for Amazon, M&S, Avios, and more!',

    seeMoreSlide: 'See more',
    slide1: 'Your card available on the go',
    slide2: 'Pay for fuel in the app, no need to leave the car',
    slide3: 'Find the nearest station with station finder',

    amazonGiftCard: 'Gift Card for Amazon',
    digitalRewardCodeForLove2shop: 'Digital Reward Code for Love2shop',
    giftCardForMS: 'Gift Card for M&S',
    pictureHouseCinema: 'Picturehouse Cinema',
    virginWines6BottleCase: 'Virgin Wines - 6 Bottle Case',

    howToAccessBpMeLink: 'How to access my BPme rewards account?',
  },

  homePageHowItWorksUs: {
    title: 'Exclusive offers:',
    learnMore: 'Learn more',

    saveCard: '{{partnerLevel}} Tier drivers save {{discount.fuel.save}}¢ on every gallon.',

    signupBonusTitle: '15¢ off per gallon bonus',
    signupBonusDetails: 'You’ll receive an additional 15¢ per gallon welcome bonus after your first transaction!',

    priceMatchCard: 'Platinum and Diamond driver get free access to Price Match',
    savingOpportunitiesCard: 'See all savings opportunities',

    download: 'Download the earnify',
    downloadUSText: 'app for additional benefits:',
    howToAccessBpMeLink: 'How to access my earnify™ account?',

    slide1: 'View you fuel discount balance and savings in the app',
    slide2: 'Pay for fuel in the app, no need to leave the car',
    slide3: 'Find the nearest station with station finder',
    slide4: 'In select cities (city names) we have an extra specific rewards offer!',
    slide5:
      'Drivers still get their cpg discount based on Uber tier status! Now drivers also earn points for every dollar you spend, both on fuel & cstore items!',
    slide6: 'Earn 1 point for every $1 spent on fuel and 2 points for every $1 spent on c-store items',
    slide7: 'Exchange points for more fuel or c-store discounts and free items',
    slide8: `Don’t see your city? Keep checking back as we’ll be adding more stations with this offer throughout 2024!`,
  },

  errorModal: {
    title: `It's us, not you`,
    description: 'Sorry, something went wrong. Please refresh the screen.',
    button: 'Refresh',
  },

  homePageModalUk: {
    earningTitle: 'Earning points',
    earningCard1Title: 'How it works',
    earningCard1Text1: 'With Uber Pro {{ tier }} Tier',
    earningCard1Text2:
      'you earn {{ discount.fuel.points }} points for every litre of fuel filled or every £{{ discount.inStore.value }} spent in store.',
    earningCard1ModalTitle: 'Partner stores',
    earningCard1ModalBody:
      'Uber Pro BPme Rewards offer on shop only available at bp M&S Food or Connect stores. In dealer owned and operated bp branded services stations Uber drivers will earn base BPme Rewards points on qualifying shop purchases: 1 point per each £1 spent in store.',
    earningCard1ModalClose: 'Okay got it',
    earningThisMeans: 'This means:',
    earningThisMeansText:
      '1l = {{ discount.fuel.points }} pts <br /> £{{ discount.inStore.value }} = {{ discount.fuel.points }} pts',
    earningCard2Title: 'Welcome bonus',
    earningCard2Text2: 'You also get welcome 500 bonus points worth £2.50 that are ready to redeem.',

    redeemTitle: 'Redeeming points',
    redeemCard1Title1: 'How it works',
    redeemCard1Text1: 'Redeem your BPme Rewards points when paying for fuel or qualifying purchases in-store.',
    redeemCard1Title2: 'You can redeem:',
    redeemCard1Text2: '{{ discount.fuel.points }} pts = {{ discount.fuel.save }}p',
    redeemCard2Title1: 'Digital vouchers',
    redeemCard2Text2:
      'Redeem online for vouchers with our amazing partners such as Amazon, M&S, Love2Shop, Virgin Wines or Avios. Simply visit: <a href="https://bpmerewards.co.uk/redeem">bpmerewards.co.uk/redeem</a>.',

    uberProTiersOffersTitle: 'Uber Pro Tiers offers',
    uberProTiersOffersCardTitle: 'How it works',
    uberProTiersOffersCardText1: 'The higher your Uber Pro tier, the more BPme Rewards points you’ll earn.',

    uberProTierTitle: 'Uber Pro<br />Tier',
    fuelOfferTitle: 'Fuel offer',
    inStoreOfferTitle: 'In-store<br />offer',

    blueTier: 'Blue',
    goldTier: 'Gold',
    platinumTier: 'Platinum',
    diamondTier: 'Diamond',

    fuelOffer: '<strong>{{ discount.fuel.points }} pts/l</strong><span>(save {{ discount.fuel.save }}ppl)</span>',
    inStoreOffer:
      '<strong>{{ discount.inStore.points }} pts/1£</strong><span>(save {{ discount.inStore.save }}p)</span>',

    uberProTiersOffersCardText2: 'Additionally, you get <strong>500 points</strong> of welcome bonus for each tier.',
    uberProTiersOffersCardText3:
      'As soon as your Uber tier changes, your BPme Rewards level will be adjusted automatically to the new tier after the first transaction.',
    uberProTiersOffersCardText4: 'Limitations apply. Available on up to 600 litres of fuel per month.',

    accessTitle: 'How to access my BPme rewards account?',
    accessCard1Title: 'Access your BPme Rewards account',
    accessCard1Text:
      'To access your BPme Rewards account, track savings and update any personal information, simply download the <a href="https://www.bpmerewards.co.uk/download/">BPme mobile app</a> or visit the <a href="https://www.bpmerewards.co.uk/">BPme Rewards website</a>.',
    accessCard2Title1: 'Logging in for the first time?',
    accessCard2Title2: 'Passwordless user accounts',
    accessCard2Text1:
      'Use the phone number associated with your Uber account to receive a one-time passcode and log in.',
    accessCard2Title3: 'Non Passwordless user accounts',
    accessCard2Text2:
      'If you don’t have passwordless set up and/or can’t use your phone number, you can use your email address associated with your Uber account and select “forgot password” and follow the instructions provided.',
    accessCard2Text3:
      'Alternatively, simply call our helpdesk on 0800 402 402 with Option 1 or email us at careline@bp.com',
  },

  homePageModalAu: {
    uberTiersTitle: 'Uber Pro Tier offers',
    howItWorksTitle: 'How it works',
    howItWorksDescription: 'The higher your Uber Pro tier, the more rewards you’ll earn.',

    uberProTierTitle: 'Uber Pro<br />Tier',
    fuelOfferTitle: 'Everyday Fuel<br />Discount',
    fuelOffer: '<strong>{{discount.fuel.save}}¢/litre</strong>',
    howItWorksDescription2:
      'As soon as your Uber tier changes, your BP Rewards Unlocked account level will be automatically adjusted to the new tier within 2 business days.',

    aboutTheOffer: 'About the offer',
    aboutTheOfferHowItWorksTitle: 'How it works',
    aboutTheOfferHowItWorksDescription1:
      'By logging into your account on the BP Rewards website, you can choose whether to earn BP Points or Qantas Points each time you fill up or shop at participating bp stores.',
    aboutTheOfferHowItWorksDescription2: 'When you scan your card in-store, you wil receive:',
    aboutTheOfferHowItWorksDiscountBlue: `
    - 2 points per 1 litre on BP Ultimate Unleaded<br />
    - 1 point per 1 litre on all other fuels<br />
    - 1 point per $1 spend in-store on eligible items<br />
    `,
    aboutTheOfferHowItWorksDiscountGreen: `
    - 2 points per 1 litre on BP Ultimate Unleaded<br />
    - 1 point per 1 litre on all other fuels<br />
    - 1 point per $1 spend in-store on eligible items<br />
    `,
    aboutTheOfferHowItWorksDiscountGold: `
    - 2 points per 1 litre on BP Ultimate Unleaded<br />
    - 1 point per 1 litre on all other fuels<br />
    - 1 point per $1 spend in-store on eligible items<br />
    `,
    aboutTheOfferHowItWorksDiscountPlatinum: `
    - 2 points per 1 litre on BP Ultimate Unleaded<br />
    - 1 point per 1 litre on all other fuels<br />
    - 1 point per $1 spend in-store on eligible items<br />
    `,
    aboutTheOfferHowItWorksDiscountDiamond: `
    - 2 points per 1 litre on BP Ultimate Unleaded<br />
    - 1 point per 1 litre on all other fuels<br />
    - 1 point per $1 spend in-store on eligible items<br />
    `,
    aboutTheOfferHowItWorksDescription3: 'Terms and conditions apply.',
  },

  homePageModalUs: {
    priceMatchTitle: 'Uber Pro Tier offers',
    priceMatchCardTitle: 'How it works',
    priceMatchCardText1: 'The higher your Uber Pro tier, the more rewards you’ll earn.',

    uberProTierTitle: 'Uber Pro<br />Tier',
    fuelOfferTitle: 'Everyday<br />Fuel<br />Discount&nbsp;<sup>1</sup>',
    additionalBenefitsTitle: 'Additional<br />Benefits&nbsp<sup>2</sup>',
    welcomeBonusTitle: 'Welcome<br />Bonus',

    fuelOffer: '{{discount.fuel.save}}¢/gallon',
    priceMatch: 'Price Match',

    priceMatchCardText2: 'Additionally, you get <strong>15¢ per gallon</strong> of welcome bonus for each tier.',
    priceMatchCardText3:
      'If your Uber tier changes, your earnify™ level will automatically adjust to the new tier after the first transaction in the new tier.',

    priceMatchModalIconTitle: 'Price Match',
    priceMatchModalIconButton: 'Okay got it',
    priceMatchModalIconBody:
      'Price Match takes the stress out of filling up by matching the best gas prices within 2-miles of bp and Amoco stations.<sup>3</sup> Usually 99¢ a month, drivers with Uber can get Price Match for free with earnify™. when you visit a bp or Amoco station.',

    welcomeBonusModalIconTitle: '15¢ Welcome Bonus',
    welcomeBonusModalIconButton: 'Okay got it',
    welcomeBonusModalIconBody:
      'A one-time 15 cent per gallon reward will be added your earnify™ account within 24 hours of your first transaction with earnify™.<sup>4</sup> This bonus reward will be automatically redeemed on the following earnify™ fuel purchase at participating bp and Amoco locations.',

    explanation1:
      '<sup>1</sup> Uber Pro drivers earn an Everyday Fuel Discount which is a cent per gallon discount based on the Uber Pro tier level. Drivers maintain that discount until the Uber Pro driver tier changes, at this time the Everyday Fuel Discount will adjust to the new tier after the first transaction in the new tier. The $100 qualification each month for Uber Pro drivers is waived. If an Uber Pro driver does not meet the $100 spend requirement, an Uber Pro Bonus reward will be issued based on the earnify™ account by the 2nd day of the calendar month and will be available until the end of the calendar month it is issued for up to 99 transactions.',
    explanation2:
      '<sup>2</sup> Platinum and Diamond Uber Pro drivers will be eligible for Price Match after the first transaction in the Platinum or Diamond tier. The subscription fee of 99 cents per month will be waived. To qualify for a Price Match purchase at least 8 gallons of fuel in a earnify™ transaction from any bp or Amoco branded station. Posted bp/Amoco credit price will be compared to the posted credit price according to Gas Buddy a any Exxon, Mobil, Shell, Chevron, and Marathon stations within a half mile radius at the same time. Savings automatically applied to your next earnify™ fuel transaction, up to maximum of 5 cents per gallon. Terms & conditions apply. Visit <a target="_blank" href="https://bp.com/price-match-terms">bp.com/price-match-terms</a> for details.',
    explanation3:
      '<sup>3</sup> If you purchase at least 1 gallon of fuel in a earnify™ transaction from any bp or Amoco branded station, bp will use GasBuddy® to compare the posted credit price you paid against the posted credit price at the same time at any fuel stations within a two-mile radius of the bp or Amoco station where you purchased your fuel. Price matching will be unavailable if there are no stations within a two-mile radius. If one of these stations has a lower posted credit price, bp will provide you with a cent per gallon savings that will be automatically applied to your next earnify™ fuel transaction with bp, up to a maximum of 5 cents per gallon. Price match is unavailable for the purchase of diesel fuel. See Price Match Program Terms and Conditions for more details.',
    explanation4:
      '<sup>4</sup> A one-time 15 cent per gallon reward will be added your earnify™ account within 24 hours of your first transaction with earnify™. This bonus reward will be automatically redeemed on the following earnify™ fuel purchase at participating bp and Amoco locations.',

    accessTitle: 'How to access my earnify™ account?',
    whereToAccessTitle: 'Where to access',
    whereToAccessText:
      'To access your earnify™ account, track savings and update any personal information, simply download the <a target="_blank" href="{{downloadAppLink}}">earnify™ mobile app</a> or visit the <a target="_blank" href="https://www.bp.com/en_us/united-states/home/products-and-services/bpme-rewards.html">earnify™ website.</a>',

    firstTimeLoginTitle: 'How to log in for the first time',
    firstTimeLoginText1:
      'Option 1: Use the phone number associated with your Uber account to receive a one-time passcode and log in.',
    firstTimeLoginText2:
      'Option 2: If your phone number is not setup to login, you can use the email address associated with your Uber account and select “forgot password.” Follow the instructions provided.',
    firstTimeLoginText3:
      'Otherwise you can chat with us 24/7 in-app by tapping the chat icon on the home screen or you can contact our helpdesk at <a href="mailto:bpconsumer@bp.com">bpconsumer@bp.com</a> or at 1-800-333-3991 for assistance.',
    uberProTiersOffersTitle: 'Uber Pro Tiers offers',
    uberProTiersOffersCardTitle: 'About the offer',
    uberProTiersOffersCardText1:
      'An exclusive offer for Uber Pro divers through earnify™. The higher your Uber Pro tier the more rewards you earn!',
    uberProTiersOffersCardText2:
      '1.  A one-time 15 cent per gallon reward will be added to the earnify™ account within 24 hours of completing a first transaction with earnify™. This bonus reward will be automatically redeemed on the following earnify™ fuel purchase at participating bp and Amoco locations.',
    uberProTiersOffersCardText3:
      '2. Uber Pro drivers earn an Everyday Fuel Discount which is a cent per gallon discount based on the Uber Pro tier level. Drivers maintain that discount until the Uber Pro driver tier changes, at this time the Everyday Fuel Discount will adjust to the new tier after the first transaction in the new tier.',
  },

  default: {
    loading: 'Loading',
    redirecting: 'Connecting BPme to Uber',
    redirectingUberProUs: 'Connecting earnify™ to Uber',
    redirectingAmazonPrimeUs: 'Connecting earnify™ to AmazonPrime',
    processing: 'Processing',
    redirectingUberProAu: 'Loading BP Rewards Unlocked',
    goBack: 'Go back',
  },
  title: {
    uberproNL: 'bp Reward | Earn more with Uber Pro in the Netherlands',
    uberproUS: 'bp Reward | Earn more with Uber Pro in USA',
    uberproMX: 'bp Reward | Earn more with Uber Pro in Mexico',
    uberproUK: 'bp Reward | Earn more with Uber Pro in UK',
    uberproAU: 'bp Reward | Earn more with Uber Pro in AU',
  },
  termsRegionSelector: {
    instructions: 'Choose your location:',
    title: 'Terms and Conditions',
  },
  termsNLv1: {
    back: 'Back',
    description: 'Terms and conditions for the Netherlands',
    title: 'Terms and Conditions',
  },
  termsMXv1: {
    back: 'Back',
    description: 'Terms and conditions for Mexico',
    title: 'Terms and Conditions',
  },
  termsUSv1: {
    back: 'Back',
    description: 'Terms and conditions for the United States',
    title: 'Terms and Conditions',
  },
  termsAUv2: {
    back: 'Back',
    description: 'Terms and conditions for the United States',
    title: 'Terms and Conditions',
  },
  termsUKv1: {
    back: 'Back',
    description: 'Terms and conditions for the United Kingdom',
    title: 'Terms and Conditions',
  },
  privacyRegionSelector: {
    instructions: 'Choose your location:',
    title: 'Privacy Policy',
  },
  privacyNLv1: {
    back: 'Back',
    description: 'Privacy Policy for the Netherlands',
    title: 'Privacy Policy',
  },
  privacyMXv1: {
    back: 'Back',
    description: 'Privacy Policy for Mexico',
    title: 'Privacy Policy',
  },
  privacyUSv1: {
    back: 'Back',
    description: 'Privacy Policy for the United States',
    title: 'Privacy Policy',
  },
  privacyUKv1: {
    back: 'Back',
    description: 'Privacy Policy for the United Kingdom',
    title: 'Privacy Policy',
  },
  faqNL: {
    back: 'Back',
    description: 'FAQ for the Netherlands',
    title: 'FAQ',
  },
  faqMX: {
    back: 'Back',
    description: 'FAQ for Mexico',
    title: 'FAQ',
  },
  faqUS: {
    back: 'Back',
    description: 'FAQ for the USA',
    title: 'FAQ',
  },
  faqAU: {
    back: 'Back',
    description: 'FAQ for Australia',
    title: 'FAQ',
  },
  faqUK: {
    back: 'Back',
    description: 'FAQ for the UK',
    title: 'FAQ',
  },
  home: {
    points: 'points',
    point: 'point',
    youHave: 'You have',
    greeting:
      // === available params ===
      // {{firstName}}
      'Hi {{firstName}}',
    barcodeInstructions:
      // === available params ===
      // {{loyaltyType}}
      'Show your barcode with every transaction. Your bonus {{loyaltyType}} will automatically be added.',
    linkExplanation:
      // === available params ===
      // {{loyaltyType}}
      // {{partnerType}}
      // {{partnerLevel}}
      // {{loyaltyId}}
      'Your {{loyaltyType}} <1>{{loyaltyId}}</1> account is linked to {{partnerType}}. As a {{partnerLevel}} tier driver, you earn bonus {{loyaltyType}}.',
  },
  paybackModal: {
    title: 'Come to bp, collect and pay with PAYBACK Points!',
    main1: 'You still have time, come to bp and use your PAYBACK Points.',
    main2: 'Remember you can use your PAYBACK card to',
    listItem1: 'collect PAYBACK points',
    listItem1Bold: 'until July 31st',
    listItem2: 'and redeem PAYBACK points',
    listItem2Bold: 'until October 31st.',
  },
  barcodeUk: {
    titleMessage: 'Welcome to BPme Rewards!',
    barcodeMessage1:
      'Enjoy our exclusive offer for Uber drivers and couriers. To earn BPme Rewards points and top up your savings always scan your digital card at the till inside bp service stations for fuel or shop purchases.',
    barcodeMessage2:
      "You can redeem points as a discount on your next visit. The higher your Uber tier, the more rewards you'll earn!",
    fullscreenMessage1: 'Show this barcode in-store to collect and redeem points.',
    fullscreenMessage2: 'Too Easy!',
  },
  barcodeAu: {
    titleMessage: 'BP Rewards Unlocked now active',
    barcodeMessage: 'Show this barcode in-store to enjoy fuel and shop discounts.',
    fullscreenMessage1: 'Show this barcode in-store to collect and redeem points.',
    fullscreenMessage2: 'Too Easy!',
  },
  footer: {
    // === available params ===
    // {{startYear}}
    // {{endYear}}
    copyright: 'Copyright © {{startYear}}-{{endYear}}',
    linkFAQ:
      // === indexes ===
      // 0 = Link to FAQ
      '<0>FAQs</0>',
    linkTermsAndConditions:
      // === indexes ===
      // 0 = Link to privacy statement
      '<0>Terms and conditions</0>',
    linkPrivacyStatement:
      // === indexes ===
      // 0 = Link to privacy statement
      '<0>Privacy statement</0>',
    linkPrivacyCollectionNotice:
      // === indexes ===
      // 0 = Link to privacy statement
      '<0>Privacy Collection Notice</0>',
    linkContactUs:
      // === indexes ===
      // 0 = Link to contact us
      '<0>Contact us</0>',
  },
  welcome: {
    heading:
      // === available params ===
      // {{firstName}}
      'Welcome',
    intro:
      // === available params ===
      // {{partnerType}}
      // {{loyaltyType}}
      // {{partnerLevel]]}
      'Thanks for linking your {{partnerType}} account. As a {{partnerLevel}} tier driver you are eligible to earn extra {{loyaltyCardType}} points. ' +
      'Just link your card to complete the activation!',
    partnerCta:
      // === available params ===
      // {{loyaltyCardType}}
      'Link {{loyaltyCardType}} Card',
    partnerHeading:
      // === available params ===
      // {{loyaltyCardType}}
      'Not a {{loyaltyCardType}} member?',
    partnerPromo:
      // === available params ===
      // {{loyaltyCardType}}
      // {{loyaltyCardLinkDomain}}
      // === indexes ===
      // 2 = Link to sign up
      'It only takes a few minutes to create a digital card. You can also pick up a card at bp. ' +
      'Link your new card to earn {{loyaltyCardType}}.<br /><br /><2>Sign up now at {{loyaltyCardType}}</2>',
  },
  linkPaybackCard: {
    title:
      // === available params ===
      // {{loyaltyCardType}}
      'Link your {{loyaltyCardType}} card',
    requirements: 'We just need your card number and password.',
    labelCardNumber:
      // === available params ===
      // {{loyaltyCardType}}
      '{{loyaltyCardType}} card number',
    labelCardNumberLocation:
      // === available params ===
      // {{loyaltyCardType}}
      'You can find this on your {{loyaltyCardType}} card or when you log into your {{loyaltyCardType}} account',
    labelCardNumberError:
      // === available params ===
      // {{loyaltyCardType}}
      'Please enter your 10 digit {{loyaltyCardType}} card number',
    labelPassword:
      // === available params ===
      // {{loyaltyCardType}}
      '{{loyaltyCardType}} password',
    labelPasswordForgot:
      // === available params ===
      // {{forgottenPasswordLinkDomain}}
      // === indexes ===
      // 2 = Link to forgotten password
      'Forgotten your password? Reset it at <2>{{forgottenPasswordLinkDomain}}</2>',
    physicalCardNoPass:
      // === available params ===
      // {{loyaltyCardType}}
      // {{registrationLinkDomain}}
      // === indexes ===
      // 1 = Link to register account
      'Don’t have a password for your physical {{loyaltyCardType}} card yet? Register it at <1>{{registrationLinkDomain}}</1>.',
    labelPasswordError: 'Please enter a valid password',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',
    submissionErrorTitle: "We couldn't link your card",
    submissionErrorDetails: 'Please check your card number and password and try again.',
  },
  paybackRewardsHome: {
    promoHeading2: 'Sign up and earn every day',
    promoDownloadPaybackApp: 'Download the PAYBACK app',
  },
  linkFreebeesCard: {
    title:
      // === available params ===
      // {{loyaltyCardType}}
      'Link your {{loyaltyCardType}} card',
    requirements: 'We just need your card number and password.',
    labelCardNumber:
      // === available params ===
      // {{loyaltyCardType}}
      '{{loyaltyCardType}} card number',
    labelCardNumberLocation:
      // === available params ===
      // {{loyaltyCardType}}
      'You can find this on your {{loyaltyCardType}} card or when you log into your {{loyaltyCardType}} account',
    labelCardNumberError:
      // === available params ===
      // {{loyaltyCardType}}
      'Please enter your 14 digit {{loyaltyCardType}} card number',
    labelPassword:
      // === available params ===
      // {{loyaltyCardType}}
      '{{loyaltyCardType}} password',
    labelPasswordForgot:
      // === available params ===
      // {{forgottenPasswordLinkDomain}}
      // === indexes ===
      // 2 = Link to forgotten password
      'Forgotten your password? Reset it at <2>{{forgottenPasswordLinkDomain}}</2>',
    physicalCardNoPass:
      // === available params ===
      // {{loyaltyCardType}}
      // {{registrationLinkDomain}}
      // === indexes ===
      // 1 = Link to register account
      'Don’t have a password for your physical {{loyaltyCardType}} card yet? Register it at <1>{{registrationLinkDomain}}</1>.',
    labelPasswordError: 'Please enter a valid password',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',
    submissionErrorTitle: "We couldn't link your card",
    submissionErrorDetails: 'Please check your card number and password and try again.',
  },
  bpMeRewardsWelcome: {
    greeting: 'Hi',
    // === available params ===
    // {{partnerLevel}}
    // {{partner}}
    partnerAndPartnerLevel: '{{partner}} {{partnerLevel}} Tier',
    heading: 'Enter your mobile number',
    explanation: 'Please provide your mobile number to set up and verify your account',
    mobilePhoneNumber: 'Mobile number',
    validPhoneNumber: 'Please provide your mobile number to set up and verify your account',
    sendVerificationCode: 'Continue',
    errorInvalidPhoneNumber: 'Please enter a valid mobile number',
    alreadyRegisteredHeading: 'Mobile number already registered',
    alreadyRegisteredBody:
      'The mobile number is already registered to a BPme account. Please use another phone number or contact the helpdesk at <1>bpconsumer@bp.com</1> or at 1-800-333-3991 for assistance.',
  },
  bpMeRewardsHome: {
    altIdInstruction:
      'Use your mobile phone number at the pump or inside the store for your fuel purchase to qualify for earnify™.',
    altIdHeading: 'Alternate ID',
    availableReward: 'Available Reward',
    // === available params ===
    // {{partnerType}}
    bpMeRewardsHeading: 'My {{partnerType}} earnify™',
    fixedForPartnerLevel:
      // === available params ===
      // {{partnerLevel}}
      'fixed for {{partnerLevel}} tier drivers',
    centsPerGallon: '¢ / gallon',
    priceMatchTitle: 'Price Match',
    priceMatchDescription: 'Price assured on your next purchase',
    priceMatchSubscription:
      // === available params ===
      // {{partnerLevel}}
      'free subscription for {{partnerLevel}} tier drivers',
    promoHeading: 'Get even more with earnify™ app',
    promoHeaderContentPart1: 'Go contact-free with earnify™! ',
    promoContentPart1:
      'Just download the app to pay and save, view receipts, track rewards and more—all from the comfort of your car.',
    promoContentPart2:
      'When you log in to the app for the first time you will use the email address you registered with and you will need to reset your password.',
    promoContentPart3: 'For easy and secure access add your digital card to Apple Wallet or Google Pass.',
    promoDownloadBpMeApp: 'Download earnify™ app',
    gettingStarted: 'Getting started',
    aboutTheOffer: 'About the offer',
    accessingYourBpMe: 'Accessing your earnify™ account',
    maximizeYourSavings: 'Maximize your savings',
  },

  bpMeRewardsUkHome: {
    availableReward: 'Available Reward',
    // === available params ===
    // {{partnerType}}
    bpMeRewardsHeading: 'My {{partnerType}} BPme Rewards',
    fixedForPartnerLevel:
      // === available params ===
      // {{partnerLevel}}
      'fixed for {{partnerLevel}} tier drivers',
    centsPerGallon: '¢ / gallon',
    priceMatchTitle: 'Price Match',
    priceMatchDescription: 'Price assured on your next purchase',
    priceMatchSubscription:
      // === available params ===
      // {{partnerLevel}}
      'free subscription for {{partnerLevel}} tier drivers',
    promoHeading: 'Get even more with BPme app',
    promoContentPart1:
      'Go contact-free with BPme! Just download the app to manage your Uber BPme Rewards account on the go.',
    promoContentPart2:
      'With the BPme app you can access your digital card, check savings balance, pay for fuel from the comfort of your car and find the nearest bp service station.',
    promoContentPart3: 'For easy and secure access add your digital card to Apple Wallet or Google Pass.',
    promoDownloadBpMeApp: 'Download BPme app',
    gettingStarted: 'More Information',
    aboutTheOffer: 'About BPme Rewards with Uber',
    accessingYourBpMe: 'Access your BPme Rewards account',
    maximizeYourSavings: 'How to redeem your points',
  },
  bpMeRewardsAuHome: {
    availableReward: 'Available Reward',
    // === available params ===
    // {{partnerType}}
    bpMeRewardsHeading: 'My {{partnerType}} BPme Rewards',
    fixedForPartnerLevel:
      // === available params ===
      // {{partnerLevel}}
      'fixed for {{partnerLevel}} tier drivers',
    centsPerGallon: '¢ / gallon',
    priceMatchTitle: 'Price Match',
    priceMatchDescription: 'Price assured on your next purchase',
    priceMatchSubscription:
      // === available params ===
      // {{partnerLevel}}
      'free subscription for {{partnerLevel}} tier drivers',
    promoHeading: 'Download the BPme app now',
    promoHeaderContentPart1: '',
    promoContentPart1:
      'Access your digital BP Rewards card to scan in-store and view your offers and points information (BP Points or Qantas Points).',
    promoContentPart2: '',
    promoContentPart3: 'For easy and secure access add your digital card to Apple Wallet or Google Pass.',
    promoDownloadBpMeApp: 'Download BPme app',
    gettingStarted: 'Find out more',
    aboutTheOffer: 'About the offer',
    rewardTiers: 'Reward tiers',
    participatingBpSites: 'Find participating bp sites',
    unlockedStoreFinder: "Watch the 'how to find a site' video here",
  },
  bpMeRewardsRegistration: {
    heading: 'Verify mobile number',
    explanation: 'Verification code',
    edit: 'Edit',
    passcodeLabel: 'Verification code',
    mobileVerificationWording:
      // === available params ===
      // {{phoneNumber}}
      'We sent an SMS with a code on <1>+1{{phoneNumber}}</1>.',
    validPasscode: 'The code you have entered is invalid. Please try again or press Send new code.',
    verify: 'Continue',
    didNotReceiveCode: 'Send new code',
    resendSMS: 'Resend SMS',
    passcodeErrorTitle: 'Incorrect verification code',
    passcodeErrorDetails:
      'The verification code entered is incorrect. Please enter the correct code or tap Resend SMS to request a new code.',
    passcodeErrorText:
      'The verification code entered is incorrect. Please enter the correct code or tap <2>Resend SMS</2> to request a new code',
    passcodeRetryLimitText:
      "Oops... You've reached the limit on retries. To get a new verification code click <2>send new code</2>",
  },
  bpMePhoneVerification: {
    heading: 'Enter your mobile number',
    explanation: 'Please provide your mobile number to set up and verify your account',
    mobileNumber: 'Mobile number',
    continueButton: 'Continue',
    validPhoneNumber: 'It appears you have entered an invalid mobile number. Please check and try again.',
    verificationHeading: 'Verification',
    mobileVerificationCode: 'Verification code',
    sendNewCodeButton: 'Resend code',
    resendingCode: 'Resending code...',
    mobileVerificationWording:
      // === available params ===
      // {{phoneNumber}}
      "We've sent an SMS with a 6-digit verification code to +44{{phoneNumber}}.",
    validVerificationCode: 'The code you have entered is invalid. Please try again or press Send new code.',
    alreadyRegisteredBody:
      'This mobile number is registered with an existing bp account. Please use another phone number or contact our helpdesk at bpmerewards@bp.com or at 0800 402402 (Option 1)  for assistance.',
    bypassPasswordlessError:
      "Oops we've noticed you're not receiving your verification code. You can continue to enrol onto our UberPro rewards program with your new account by clicking the Continue button. However, to log in to your account on our bpme mobile app you will have to use your email address and click on Forgot Password and follow the steps provided.",
    bypassPasswordlessError2:
      'Otherwise contact our helpdesk at bpmerewards@bp.com or at 0800 402402 (Option 1)  for assistance.',
    bypassOtpRegistration:
      'You are already a BPme Rewards member. Click Continue to setup your Uber BPme Rewards offer.',
    bpAlreadyRegisterMessage:
      "The mobile number you have entered is registered with an existing BP Rewards account. Click the 'Continue' button to complete your enrolment. When you first log into BP Rewards website use your email address and click 'forgot password' and follow steps provided.",
  },
  bpAuPhoneVerification: {
    heading: 'Enter your mobile number',
    explanation: 'Please provide your mobile number to set up and verify your account',
    mobileNumber: 'Mobile number',
    continueButton: 'Continue',
    validPhoneNumber: 'It appears you have entered an invalid mobile number. Please check and try again.',
    verificationHeading: 'Verify your mobile number',
    mobileVerificationCode: 'Verification code',
    sendNewCodeButton: 'Resend code',
    resendingCode: 'Resending code...',
    mobileVerificationWording:
      // === available params ===
      // {{phoneNumber}}
      "We've sent an SMS with a 6-digit verification code to +61{{phoneNumber}}.",
    validVerificationCode: "Oops! The code you have entered is invalid. Please try again or press 'Resend code' below.",
    alreadyRegisteredBody:
      'This mobile number is registered with an existing bp account. Please use another phone number or contact us at bpcustomers.secure.force.com/gcmcontactus for assistance.',
    bypassPasswordlessError:
      "Still haven't received your verification code? You can continue to enrol onto the BP Rewards Unlocked program by clicking the 'Continue' button. However, to log in to your account for the first time on the BP Rewards website you will need to use your email address and click on 'forgot password' and follow the steps provided.",
    bypassOtpRegistration:
      'You are already a BP Rewards member. Click continue to setup your BP Rewards Unlocked offer.',
    bpAlreadyRegisterMessage:
      "The mobile number you have entered is registered with an existing BP Rewards account. Click the 'Continue' button to complete your enrolment. When you first log into BP Rewards website use your email address and click 'forgot password' and follow steps provided.",
  },
  tandcUS: {
    title: 'Nearly there',
    linkTandC: 'Terms and Conditions',
    acceptError: 'Please accept terms and conditions',
    acceptAgreement: 'By creating an account, you are agreeing to bp’s Terms and Conditions.',
    labelMarketing: 'I would like to receive bp marketing emails ',
    agreeMarketing: 'If you wish to receive marketing communications, please tick this box.',
    buttonSubmit: 'Confirm sign up',
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'To receive bp’s offer and to use earnify™, you’ll need to review and accept our <1>Terms and Conditions</1> and read our <3>Privacy Statement</3>.',
    labelTandC: 'I agree to the Terms and Conditions and I have read and understood the Privacy Statement',
    marketingSectionHeader:
      'Keep up to date with new offers and the latest news so that you can get the most out of earnify™.',
    nameLabel: 'Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    emailMarketingTitle: 'Email me about rewards and updates',
    registrationTitle: 'Registration',
    registrationHelperText: "We will set up your earnify™ account using the information we've got from Uber.",
    smsMarketingTitle: 'Text me about rewards and updates',
    smsMarketingSubTitle:
      'Recurring automated marketing messages will be sent to the mobile number provided at opt in. Consent is not a condition of purchase. Msg and data rates may apply. Text STOP to quit and HELP for help. ',
  },
  tandcUK: {
    title: 'Terms and Conditions',
    linkTandC: 'Terms and Conditions',
    acceptError: 'Please accept terms and conditions',
    acceptAgreement: 'By creating an account, you are agreeing to bp’s Terms and Conditions.',
    labelMarketing: 'I would like to receive bp marketing emails ',
    agreeMarketing: 'If you wish to receive marketing communications, please tick this box.',
    buttonSubmit: 'Submit',
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'To receive bp’s offer and to use BPme Rewards, you’ll need to review and accept our <1>Terms and Conditions</1> and <3>Privacy Statement</3>.',
    labelTandC:
      'Please tick this box to confirm you are 16+ and accept the Terms and Conditions and Privacy Statement.',
    marketingSectionHeader:
      'By providing your email address, you agree that bp may send you information and offers by this channel on the basis of the BPme Rewards Privacy Statement. If you do not wish to receive such communications please untick the box.',
    emailMarketingTitle: 'Email me about BPme Rewards and updates',
    nameLabel: 'Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    registrationTitle: "Let's get you ready to save",
    registrationHelperText: "We will set up your BPme Rewards account using the information we've got from Uber.",
    smsMarketingTitle: 'Text me about rewards and updates',
    smsMarketingSubTitle:
      'Recurring automated marketing messages will be sent to the mobile number provided at opt in. Consent is not a condition of purchase. Msg and data rates may apply. Text STOP to quit and HELP for help. ',
    privacyPolicyLink: 'https://www.bpmerewards.co.uk/privacy-statement/',
    tandcLink: 'https://www.bpmerewards.co.uk/terms-and-conditions/',
  },
  tandcAU: {
    title: 'Terms and Conditions',
    linkTandC: 'Terms and Conditions',
    acceptError: 'Please accept terms and conditions',
    privacyAcceptError: 'Please accept privacy policy conditions',
    acceptAgreement: 'By creating an account, you are agreeing to bp’s Terms and Conditions.',
    labelMarketing: 'I would like to receive bp marketing emails ',
    agreeMarketing: 'If you wish to receive marketing communications, please tick this box.',
    buttonSubmit: 'Confirm',
    reviewPolicies: 'To receive bp’s offer for eligible Uber drivers and to use BP Rewards, you acknowledge that:',
    labelTandC:
      'You have read, understood, and accept the <1>BP Rewards & BPme Terms and Conditions</1> and the <3>BP Rewards Unlocked for Uber Pro Terms and Conditions</3>',
    marketingSectionHeader:
      'By providing your email address, you agree that bp may send you information and offers by this channel on the basis of the BPme Rewards Privacy Statement. If you do not wish to receive such communications please untick the box.',
    emailMarketingTitle: 'Email me about BPme Rewards and updates',
    nameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Email',
    registrationTitle: "Let's unlock your exclusive rewards!",
    registrationHelperText:
      'Below is the information you previously provided to Uber. Using this will help us get your BP Rewards Unlocked account set up quickly.',
    smsMarketingTitle: 'Text me about rewards and updates',
    smsMarketingSubTitle:
      'Recurring automated marketing messages will be sent to the mobile number provided at opt in. Consent is not a condition of purchase. Msg and data rates may apply. Text STOP to quit and HELP for help. ',
    privacyPolicyLink: 'https://www.bpmerewards.co.uk/privacy-statement/',
    tandcLink0: 'https://bprewards.com.au/terms-and-conditions',
    tandcLink1: 'https://bprewards.com.au/terms-and-conditions',
    privacyLink0: 'https://bprewards.com.au/privacy-statement',
    privacyCollectionNoticeLink0: 'https://bprewards.com.au/privacy-collection-notice',
    privacyLink1: 'https://bprewards.com.au/privacy-statement',
    faqLink: 'https://bprewards.com.au/help',
    bpRewardsUnlocked: 'BP Rewards Unlocked',
    additionalBenefits: 'Additional Benefits',
    localParticipatingSitesLink:
      'https://bpretaillocator.geoapp.me/?locale=en_AU&prefilters=facilities.bp_rewards_unlocked',
    bpRewardsLink: 'https://bprewards.com.au',
    rewardsSecondaryMessage:
      // === indexes ===
      // 1 = Link to BP Rewards Website
      'In addition to fuel and shop discounts, BP Rewards Unlocked members can choose to earn BP Points or Qantas Points each time you fill up or shop at participating bp stores. Explore more benefits on the <1>BP Rewards Website</1> now.',
    privacyText: 'You have read and understood bp’s <1>Privacy Collection Notice</1> and <3>Privacy Policy</3>',
  },
  tandcNL: {
    title: 'Terms and Conditions',
    linkTandC: 'Terms and Conditions',
    acceptError: 'Please accept terms and conditions',
    acceptAgreement: 'By creating an account, you are agreeing to bp’s Terms and Conditions.',
    labelMarketing: 'I would like to receive bp marketing emails ',
    agreeMarketing: 'If you wish to receive marketing communications, please tick this box.',
    buttonSubmit: 'Confirm sign up',
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'To complete your sign up to BP’s offer, please review and accept our <1>Terms and Conditions</1>. To understand how your data will be used by BP, please read our <3>Privacy Statement</3>.',
    labelTandC: 'I agree to Terms & Conditions',
    marketingSectionHeader: 'If you wish to recieve marketing communications, please tick this box',
    emailMarketingTitle: 'I would like to receive bp marketing emails',
    nameLabel: 'Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    registrationTitle: 'Registration',
    registrationHelperText: "We will set up your BPme Rewards account using the information we've got from Uber.",
    smsMarketingTitle: '',
    smsMarketingSubTitle: '',
  },
  tandcMX: {
    title: 'Terms and Conditions',
    linkTandC: 'Terms and Conditions',
    acceptError: 'Please accept terms and conditions',
    acceptAgreement: 'By creating an account, you are agreeing to bp’s Terms and Conditions.',
    labelMarketing: 'I would like to receive bp marketing emails ',
    agreeMarketing: 'If you wish to receive marketing communications, please tick this box.',
    buttonSubmit: 'Confirm sign up',
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'To receive bp’s offer and to use BPme Rewards, you’ll need to review and accept our Terms and Conditions <1>Terms and Conditions</1> and read our Privacy Statement <3>Privacy Statement</3>.',
    labelTandC: 'I agree to the Terms and Conditions and I have read and understood the Privacy Statement',
    marketingSectionHeader:
      'Keep up to date with new offers and the latest news so that you can get the most out of BPme Rewards.',
    emailMarketingTitle: 'Email me about rewards and updates',
    nameLabel: 'Name',
    lastNameLabel: 'Last Name',
    emailLabel: 'Email',
    registrationTitle: 'Registration',
    registrationHelperText: "We will set up your BPme Rewards account using the information we've got from Uber.",
    smsMarketingTitle: 'Text me about rewards and updates',
    smsMarketingSubTitle:
      'Recurring automated marketing messages will be sent to the mobile number provided at opt in. Consent is not a condition of purchase. Msg and data rates may apply. Text STOP to quit and HELP for help. ',
  },
  tandcGlobal: {
    title: 'Terms and Conditions',
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'To complete your sign up to BP’s offer, please review and accept our <1>Terms and Conditions</1>. To understand how your data will be used by BP, please read our <3>Privacy Statement</3>.',
    labelTandC: 'I agree to Terms & Conditions',
    linkTandC: 'Terms and Conditions',
    acceptError: 'Please accept terms and conditions',
    acceptAgreement: 'By creating an account, you are agreeing to bp’s Terms and Conditions.',
    labelMarketing: 'I would like to receive bp marketing emails ',
    agreeMarketing: 'If you wish to receive marketing communications, please tick this box.',
    buttonSubmit: 'Confirm sign up',
  },
  languageSelector: {
    en: 'English',
    nl: 'Dutch (Nederlands)',
    es: 'Spanish (Español)',
  },
  error: {
    title401: '401 error',
    heading401: 'You aren’t authorised to view that page',
    message401:
      // === available params ===
      // {{partnerType}}
      'You need to be signed in to access this site. Please sign in using your {{partnerType}} email and password.',
    cta401: 'Sign In',
    title404: '404 error',
    heading404: 'Sorry we can’t find that page',
    message404: 'That page doesn’t seem to exist. Please check the website address is correct and try again.',
    cta404: 'Go Back',
    title500: '500 error',
    heading500: 'Oops! Something’s gone wrong',
    message500:
      'Our service isn’t working at the moment and we’re working to fix the problem. Please try again a little later.',
    cta500: 'Go Back',
  },
  endOfRedemptionPeriod: {
    title: 'PAYBACK Points have come to an end',
    main: 'we are working on something new. Wait for it!',
  },
};
