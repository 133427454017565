import { CIPConfig, Partner } from '../../../../interfaces/Partner';
import { cipTokenStore } from '../../../../helpers/cipTokenStore';

const getCipConfig = (region: string): CIPConfig => ({
  clientId: `marvel-${region}-web`,
  redirectUri: 'https://reward.bp.com/auth/callback',
  scope: 'profile email phone b2c-contact b2c-consent b2c-profile openid',
  serverConfig: {
    baseUrl: 'https://energyid.bp.com/am/',
    timeout: 3000,
  },
  realmPath: 'bravo',
  middleware: [],
  tokenStore: cipTokenStore,
});

export default [
  {
    partnerType: 'uber-pro',
    regions: [
      {
        companyName: 'bp Europa SE',
        consents: {
          currentPrivacyPolicyVersion: '1.0',
          currentTandCVersion: '1.0',
          isSmsMarketingAutoChecked: false,
          supportedMarketingChannels: ['Email'],
          supportedPrivacyVersions: ['v1'],
          supportedTermsVersions: ['v1'],
        },
        contactEmail: 'consumentenservice@bp.com',
        copyright: {
          endYear: '2022',
          startYear: '1996',
        },
        defaultLanguage: 'nl',
        cipConfig: getCipConfig('nl'),
        idp: 'Salesforce',
        idpConfig: {
          appShortName: 'ridehailingnl',
          authSuccessUrl: 'https://reward.bp.com/auth/success',
          authorizeResponseAndScopes: 'response_type=token+id_token',
          authorizeSsoUrl: '/services/auth/sso',
          authorizeUrl: '/services/oauth2/authorize',
          clientId: '3MVG98_Psg5cppybfa6udyqaBydGO57I8wOEsTPr9K6c2IvQf9CkC3ozIgUm5msWsmrWk2H7EEQKu3HScH.Vs',
          idpDomain: 'https://id.bp.com',
          idpUsePKCE: true,
          multiRegion: true,
          redirectUrl: 'https://reward.bp.com/auth/callback',
          reidpId: 'Uber',
          revokeUrl: '/services/oauth2/revoke',
          testLogoutUrl: 'https://id.bp.com/secur/logout.jsp',
          tokenUrl: '/services/oauth2/token',
        },
        idpGrantType: 'AuthenticationCode',
        idpHybridMode: false,
        loyaltyType: 'freebees',
        regionDisplayName: 'Nederland',
        regionName: 'nl',
        supportedLanguages: ['nl', 'en'],
      },
      {
        companyName: 'bp Products North America',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '1.0',
          currentTandCVersion: '1.0',
          isSmsMarketingAutoChecked: false,
          isEmailMarketingAutoChecked: true,
          supportedMarketingChannels: ['Email', 'SMS'],
          supportedPrivacyVersions: ['v1'],
          supportedTermsVersions: ['v1'],
        },
        contactEmail: 'bpconsumer@bp.com',
        copyright: {
          endYear: '2024',
          startYear: '2016',
        },
        defaultLanguage: 'en',
        cipConfig: getCipConfig('us'),
        idp: 'Salesforce',
        idpConfig: {
          appShortName: 'bpmeusa',
          authSuccessUrl: 'https://reward.bp.com/auth/success',
          authorizeResponseAndScopes: 'response_type=token+id_token',
          authorizeSsoUrl: '/services/auth/sso',
          authorizeUrl: '/services/oauth2/authorize',
          clientId: '3MVG98_Psg5cppybfa6udyqaByX2RLPsdkNIes4cmHJdg5DJ6myhq5gYmoVndq3_jCX44sircDeQ9BgHKH227',
          idpDomain: 'https://id.bp.com',
          idpUsePKCE: true,
          multiRegion: true,
          redirectUrl: 'https://reward.bp.com/auth/callback',
          reidpId: 'Uber',
          revokeUrl: '/services/oauth2/revoke',
          testLogoutUrl: 'https://id.bp.com/secur/logout.jsp',
          tokenUrl: '/services/oauth2/token',
        },
        idpGrantType: 'AuthenticationCode',
        idpHybridMode: false,
        loyaltyType: 'bpme-rewards-us',
        regionDisplayName: 'USA',
        regionName: 'us',
        supportedLanguages: ['en'],
      },
      {
        companyName: 'bp Mexico',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '1.0',
          currentTandCVersion: '1.0',
          isSmsMarketingAutoChecked: false,
          supportedMarketingChannels: ['Email', 'SMS'],
          supportedPrivacyVersions: ['v1'],
          supportedTermsVersions: ['v1'],
        },
        contactEmail: 'contactanos@bp.com',
        copyright: {
          endYear: '2022',
          startYear: '1996',
        },
        defaultLanguage: 'es',
        cipConfig: getCipConfig('mx'),
        idp: 'Salesforce',
        idpConfig: {
          appShortName: 'bpmemexico',
          authSuccessUrl: 'https://reward.bp.com/auth/success',
          authorizeResponseAndScopes: 'response_type=token+id_token',
          authorizeSsoUrl: '/services/auth/sso',
          authorizeUrl: '/services/oauth2/authorize',
          clientId: '3MVG98_Psg5cppybfa6udyqaByZVIZPJ4d2DVyFyV01nZfxVWDFihMzZpHXEAYNBl.cc5m7l.fj1j.motZciy',
          idpDomain: 'https://id.bp.com',
          idpUsePKCE: true,
          multiRegion: false,
          redirectUrl: 'https://reward.bp.com/auth/callback',
          reidpId: 'Uber',
          revokeUrl: '/services/oauth2/revoke',
          testLogoutUrl: 'https://id.bp.com/secur/logout.jsp',
          tokenUrl: '/services/oauth2/token',
        },
        idpGrantType: 'AuthenticationCode',
        idpHybridMode: false,
        loyaltyType: 'payback-mx',
        regionDisplayName: 'M\u00e9xico',
        regionName: 'mx',
        supportedLanguages: ['es', 'en'],
      },
      {
        companyName: 'bp UK',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '2.0',
          currentTandCVersion: '2.0',
          isSmsMarketingAutoChecked: false,
          isEmailMarketingAutoChecked: true,
          supportedMarketingChannels: ['Email'],
          supportedPrivacyVersions: ['v2'],
          supportedTermsVersions: ['v2'],
        },
        contactEmail: 'https://www.bpmerewards.co.uk/contact-us/',
        copyright: {
          endYear: '2022',
          startYear: '2016',
        },
        defaultLanguage: 'en',
        idp: 'Salesforce',
        cipConfig: getCipConfig('uk'),
        idpConfig: {
          appShortName: 'bpmeuk',
          authSuccessUrl: 'https://reward.bp.com/auth/success',
          authorizeResponseAndScopes: 'response_type=token+id_token',
          authorizeSsoUrl: '/services/auth/sso',
          authorizeUrl: '/services/oauth2/authorize',
          clientId: '3MVG98_Psg5cppybfa6udyqaByX2RLPsdkNIes4cmHJdg5DJ6myhq5gYmoVndq3_jCX44sircDeQ9BgHKH227',
          idpDomain: 'https://id.bp.com',
          idpUsePKCE: true,
          multiRegion: true,
          redirectUrl: 'https://reward.bp.com/auth/callback',
          reidpId: 'Uber',
          revokeUrl: '/services/oauth2/revoke',
          testLogoutUrl: 'https://id.bp.com/secur/logout.jsp',
          tokenUrl: '/services/oauth2/token',
        },
        idpGrantType: 'AuthenticationCode',
        idpHybridMode: false,
        loyaltyType: 'bpme-rewards-uk',
        regionDisplayName: 'UK',
        regionName: 'uk',
        supportedLanguages: ['en'],
      },
      {
        companyName: 'bp AU',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '2.0',
          currentTandCVersion: '2.0',
          isSmsMarketingAutoChecked: true,
          isEmailMarketingAutoChecked: true,
          supportedMarketingChannels: ['Email', 'SMS'],
          supportedPrivacyVersions: ['v2'],
          supportedTermsVersions: ['v2'],
        },
        contactEmail: 'https://bpcustomers.secure.force.com/gcmcontactus',
        copyright: {
          endYear: '2022',
          startYear: '2016',
        },
        defaultLanguage: 'en',
        idp: 'Salesforce',
        cipConfig: getCipConfig('au'),
        idpConfig: {
          appShortName: 'bpmeau',
          authSuccessUrl: 'https://reward.bp.com/auth/success',
          authorizeResponseAndScopes: 'response_type=token+id_token',
          authorizeSsoUrl: '/services/auth/sso',
          authorizeUrl: '/services/oauth2/authorize',
          clientId: '3MVG98_Psg5cppybfa6udyqaByX2RLPsdkNIes4cmHJdg5DJ6myhq5gYmoVndq3_jCX44sircDeQ9BgHKH227',
          idpDomain: 'https://id.bp.com',
          idpUsePKCE: true,
          multiRegion: true,
          redirectUrl: 'https://reward.bp.com/auth/callback',
          reidpId: 'Uber',
          revokeUrl: '/services/oauth2/revoke',
          testLogoutUrl: 'https://id.bp.com/secur/logout.jsp',
          tokenUrl: '/services/oauth2/token',
        },
        idpGrantType: 'AuthenticationCode',
        idpHybridMode: false,
        loyaltyType: 'bpme-rewards-au',
        regionDisplayName: 'AU',
        regionName: 'au',
        supportedLanguages: ['en'],
        unlockedStoreFinderVideo: {
          partnerId: 895641,
          uiConfId: 42266921,
          videoId: '1_80wtsh57',
          videoLength: '2.23',
          videoRatio: 56.25,
        },
      },
    ],
  },
] satisfies Partner[];
