import { ProgramType } from '../../interfaces/ProgramType';
import { Theme } from './Theme';

export const defaultThemes: Record<ProgramType, Theme> = {
  fuel: {
    colors: {
      primaryBase: '#009900',
      primaryDark: '#2d3680',
      primaryLight: '#FFC20E',
      secondaryBase: '#FFC20E',
      secondaryDark: '#FFC20E',
      secondaryLight: '#FFC20E',
      tertiaryBase: '#FFC20E',
      tertiaryDark: '#FFC20E',
      tertiaryLight: '#CCFF00',
    },
  },
  // todo: pulse colors still needs to be redefined
  pulse: {
    colors: {
      primaryBase: '#009900',
      primaryDark: '#007833',
      primaryLight: '#FFC20E',
      secondaryBase: '#FFC20E',
      secondaryDark: '#FFC20E',
      secondaryLight: '#FFC20E',
      tertiaryBase: '#FFC20E',
      tertiaryDark: '#FFC20E',
      tertiaryLight: '#CCFF00',
    },
  },
};
