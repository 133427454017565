import { ReactElement, createContext, useContext } from 'react';
import Cookies from 'js-cookie';
import { getMarvelLogger } from '@marvel-common/bp-digital-logging';

import { FeatureFlags } from '../interfaces/FeatureFlag';

export const defaultMarvelFeatureFlags = {
  'uber-pro': { au: 'cip', us: 'cip', uk: 'cip', nl: 'sf', mx: 'sf' },
  'amazon-prime': { us: 'cip' },
};

const FeatureFlagsContext = createContext<FeatureFlags>(defaultMarvelFeatureFlags);

const useFeatureFlagsContext = (): FeatureFlags => useContext(FeatureFlagsContext);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FeatureFlagsContextProvider = ({ children }: { children?: ReactElement }): JSX.Element => {
  const logger = getMarvelLogger('loadLoyaltyBalance');

  let featureFlags: FeatureFlags = defaultMarvelFeatureFlags;
  try {
    featureFlags = JSON.parse(
      Cookies.get('custom-feature-flags') ??
        Cookies.get('marvel-feature-flags') ??
        process.env.REACT_APP_MARVEL_FEATURE_FLAGS_DEFAULT ??
        JSON.stringify(defaultMarvelFeatureFlags),
    ) as FeatureFlags;
  } catch (e: unknown) {
    logger.error('failed to parse featureFlag: ', (e as Error).message);
  }

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
};

export { FeatureFlagsContextProvider, useFeatureFlagsContext, FeatureFlagsContext };
